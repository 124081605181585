<template>
  <div>
    <vue-headful :title="pageTitle" />
    <div class="has-text-centered has-background-primary" style="margin-bottom: 20px;">
      <h1
        class="is-size-6 has-text-white"
        style="padding: 5px 0px"
      >{{ pageheading.toLocaleUpperCase() }}</h1>
    </div>
    <div style="max-width: 95%; margin: auto;">
      <div v-if="model === null || model.id === undefined">
        <Loading />
      </div>
      <div v-else id="body-content-area">
        <form @submit="handleFormSubmit" id="pagelayout">
          <div class="columns">
            <div class="column is-two-thirds-desktop">
              <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray; max-width: 75%;">
                <span class="has-text-weight-bold">Document Properties</span>
              </div>
              <div class="block">
                <div>

                  <div  class="field">
                    <label class="label is-size-7">
                      Label
                      <span class="has-text-danger" style="padding-left: 3px;">*</span>
                    </label>
                    <div class="control">
                      <input type="text" class="input" v-model="model.displayName" style="max-width: 75%;">
                    </div>
                  </div>

                  <div class="field" v-if="reportTypes !== null">
                    <label class="label is-size-7">
                      Document Type
                      <span class="has-text-danger" style="padding-left: 3px;">*</span>
                    </label>
                    <div class="control">
                      <Select :options="reportTypes" :default="model.typeCode" style="max-width: 75%" ref="documentType" identifier="documentType" @onchange="handleSelectChange"/>
                    </div>
                  </div>

                   <div class="field">
                      <label class="label is-size-7">
                        Upload Date
                        <span class="has-text-warning"
                              style="padding-left: 3px;">*</span>
                      </label>
                      <kendo-datepicker id="uploaddate"
                        name="uploaddate"
                        style="width: 200px"
                        required="required"
                        v-model="model.uploadDate"
                        :format="'yyyy-MM-dd'"
                      >
                      </kendo-datepicker>
                    </div>

                  <div  class="field">
                    <label class="label is-size-7">
                      Document Year Override
                    </label>
                    <div class="control">
                      <input type="text" :class="{ 'input': true, 'is-danger': inputError.yearOverride }" v-model="model.yearOverride" style="max-width: 75%;">
                    </div>
                  </div>

                  <div class="has-background-warning has-text-white" style="padding: 0.5rem 1rem 1rem 1rem; margin-bottom: 1rem;" v-show="inputError && inputError.yearOverride">
                    <span class="has-text-weight-bold">Error</span>
                    <ul id="example-1">
                      <li style="margin-top: 5px;">{{inputError.yearOverride}}</li>
                    </ul>
                  </div>

                  <div style="width: 100%; padding-left: 1rem;" v-if="fileError.length !== 0">
                    <div v-for="error in fileError" :key="error" class="has-text-danger">
                      <div style="min-width: 100%!important; margin: .25rem 0rem;">Error: {{error}}</div>
                    </div>
                  </div>
                  <div ref="filePondWrapper" style="max-width: 75%;">
                    <file-pond
                      v-if="$hasPermissions(clientSession, ['DOCUMENT_CENTER'], 2)"
                      name="file"
                      ref="filePond"
                      :label-idle="filePondLabel"
                      allow-multiple="true"
                      accepted-file-types="application/pdf, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      :files="input.files"
                      v-on:addfile="addfile"
                      v-on:removefile="removefile"
                      :dropOnElement="false"
                      :dropOnPage="true"
                      :allowMultiple="false"
                      :credits="[]"
                    />
                    <div class="has-background-warning has-text-white" style="padding: 0.5rem 1rem 1rem 1rem" v-show="errors.length>0">
                  <span class="has-text-weight-bold">Error</span>
                  <ul id="example-1">
                    <li style="margin-top: 5px;" v-for="(err,idx) in errors" :key="err + idx">{{ err }}</li>
                  </ul>
                </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-one-third-desktop">
              <div class="block" style="padding: 0px 0px 7px 0px; border-bottom: solid 1px lightgray;">
                <span class="has-text-weight-bold">Documents</span>
              </div>
              <div class="block">
                <div>
                  <div  class="field">
                    <div class="control">
                      <Checkbox :isChecked="true" @isChecked="handleIsChecked" :label="'Send email notification'"/>
                    </div>
                  </div>
                  <div  class="field" v-if="emailTemplates !== null">
                    <label class="label is-size-7">
                      Email Template
                      <span class="has-text-danger" style="padding-left: 3px;">*</span>
                    </label>
                    <div class="control">
                      <Select :options="emailTemplates" :default="1" style="max-width: 75%" ref="emailTemplate" identifier="emailTemplate" @onchange="handleSelectChange"/>
                    </div>
                  </div>
                </div>
              </div>

              <div id="report-file-info-section" style="padding-top: 40px;">
                  <div id="report-file-info-section-heading"
                       class="block"
                       style="margin-bottom: 10px; padding: 0px 0px px 0px; border-bottom: solid 1px lightgray;">
                    <span class="has-text-weight-bold">Document Information</span>
                  </div>
                  <div id="report-file-info-section-data" class="block">
                    <div>
                      <table class="table is-size-7">
                        <tr>
                          <td class="is-narrow" style="border-bottom: none;">File Name:</td>
                          <td style="border-bottom: none;">{{ fullname }}</td>
                        </tr>
                        <tr>
                          <td style="border-bottom: none;">File Type:</td>
                          <td style="border-bottom: none;">{{getFileType(original.extension)}}</td>
                        </tr>
                        <tr>
                          <td style="border-bottom: none;">File Size:</td>
                          <td style="border-bottom: none;" v-if="original.fileSize && !isNaN(original.fileSize)">{{ parseInt(original.fileSize) | prettyBytes }}</td>
                        </tr>
                        <tr>
                          <td style="border-bottom: none;">Download:</td>
                          <td style="border-bottom: none;">
                            <a :href="`${getDocumentPath(original)}`" download>
                              <i class="fas fa-download fa-2x"></i>
                            </a>
                            &nbsp;
                            <a href="" @click.prevent="viewDocument(original)"
                               :target="'_blank'">
                              <i class="fas fa-eye fa-2x"></i>
                            </a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
            </div>
          </div>
          <div id="buttongroup" style="padding-top: 20px;">
            <div class="field is-grouped">
              <div class="control">
                <button @click.prevent="handleFormSubmit" :class="[isSaving ? 'saving' : '', 'button is-accent has-text-white']" ref="saveButton" type="submit" :disabled="isSaving || !$hasPermissions(clientSession, ['DOCUMENT_CENTER'], 2)">{{ isSaving ? 'Saving' : 'Save Document' }}
                  <spin-loader v-if="isSaving"/>
                </button>
              </div>
              <div class="control">
                <a class="button is-light" v-on:click="resetForm();$router.go(-1)">Cancel</a>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from './Loading'
import { mapState } from 'vuex'
import { activeSite } from '../vuex-actions'

import vueFilePond, { setOptions } from 'vue-filepond'
import 'filepond/dist/filepond.min.css'
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginImagePreview from 'filepond-plugin-image-preview'

import { DatePicker } from '@progress/kendo-dateinputs-vue-wrapper'

import Constants from '../constants.js'
import Select from './partials/Select.vue'
import Checkbox from './partials/Checkbox.vue'

let pageName = 'Document Details'

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginImagePreview
)

const setCompOptions = (that, fileID) => {
  setOptions({
    allowRevert: false,
    allowProcess: false,
    instantUpload: false,
    server: {
      url: `${process.env.VUE_APP_API_URL}`,
      process: async (fieldName, file, metadata, load, error, progress, abort, transfer, options) => {
        let payload = new FormData()
        let completeFile = that.$refs.filePond.getFiles().filter(_file => _file.file.name === file.name)
        let extension = ''

        if (completeFile.length > 0) {
          extension = `${completeFile[0].fileExtension.includes('.') ? '' : '.'}${completeFile[0].fileExtension}`
        }

        payload.append('file', file)

        Object.keys(that.model).forEach(function (key) {
          if (that.model[key] !== undefined && that.model[key] !== null && that.model[key] !== '') {
            if (key === 'uploadDate' && that.model[key] instanceof Date) {
              payload.append('document.' + key.charAt(0).toUpperCase() + key.slice(1), that.model[key].toDateString())
            } else {
              payload.append('document.' + key.charAt(0).toUpperCase() + key.slice(1), that.model[key]) // append the modified data
            }
          }
        })

        if (extension) {
          payload.set('document.Extension', extension)
        }

        payload.append('document.UploadDate', new Date().toUTCString())

        let response = await that.$http.put(
          `documents/${that.$route.params.id}`,
          payload,
          {
            onUploadProgress (progressEvent) {
              progress(progressEvent.lengthComputable, progressEvent.loaded, progressEvent.total)
            }
          }
        )

        if (response.status >= 200 && response.status < 300) {
          // the load method accepts either a string (id) or an object
          load(response.data)
          that.complete(response)
        } else {
          // Can call the error method if something is wrong, should exit after
          error('oh no')
        }

        that.input = response.data

        // Should expose an abort method so the request can be cancelled
        return {
          abort: () => {
            // This function is entered if the user has tapped the cancel button
            response.abort()

            // Let FilePond know the request has been cancelled
            abort()
          }
        }
      },
      revert: null,
      fetch: null,
      restore: null,
      load: null
    }
  })
}

const defaultModel = {
  name: '',
  documentType: 1,
  reportType: 'AQU',
  yearOverride: ''
}

export default {
  components: {
    Loading,
    Select,
    Checkbox,
    FilePond,
    'kendo-datepicker': DatePicker
  },
  props: {
    document: {
      required: false,
      type: Object,
      default: function () {
        return defaultModel
      }
    }
  },
  beforeRouteLeave (to, from, next) {
    this.beforeWindowUnload(null, next)
  },
  data () {
    return {
      isLoading: false,
      original: {},
      Constants: Constants,
      pageheading: pageName,
      reportTypes: null,
      reportTypesArray: null,
      emailTemplates: null,
      isSaving: false,
      fileError: [],
      reportTypesMap: new Map(),
      model: null,
      modified: false,
      groups: [],
      errors: [],
      inputHash: {},
      filePondLabel: `<div style='color: #7fb942; padding-top: 1rem;'><div><span class='icon bcc-green' style='font-size: 3rem;'><i class='fas drop fa-3x fa-cloud-upload-alt' style='max-width: 50px!important; max-height: 50px!important; font-size: 1rem!important;'></i></span></div><div>Click to choose files to upload, or drop to upload them to the portal instantly</div></div>`,
      input: {
        files: [],
        shouldSendNotification: true,
        label: null,
        emailTemplate: null,
        documentType: null,
        reportType: null,
        documentYearOverride: null,
        uploadDate: null
      },
      inputError: {
        yearOverride: null
      }
    }
  },
  watch: {
    input: {
      handler (oldFile, newFile) {
        if (newFile.files.length !== 0) {
          this.filePondLabel = `<div class='link' style='color: #7fb942;'>Click to replace file</div>`
        } else {
          this.filePondLabel = `<div class='link' style='color: #7fb942;padding-top: .5rem;'><div><span class='icon bcc-green' style='font-size: 1rem;'><i class='fas drop fa-cloud-upload-alt' style='max-width: 50px!important; max-height: 50px!important; font-size: 2rem!important;'></i></span></div><div>Click here or drop a file to replace this document</div></div>`
        }
      },
      deep: true
    },
    model () {
      this.modified = true
    }
  },
  computed: {
    ...mapState([activeSite, 'clientSession']),
    pageTitle () {
      return pageName + ' - ' + this.activeSite.displayName
    },
    fullname () {
      let yy = this.original.yearOverride % 100
      let carrier = null
      let carrierId = this.original.carrierId

      this.groups.forEach((_carrier) => {
        if (_carrier.id === carrierId) {
          carrier = _carrier
        }
      })

      if (carrier) {
        return `${carrier.code}-${this.original.typeCode}-${yy}-${this.original.name}${this.original.extension}`
      }
      return `${this.original.typeCode}-${yy}-${this.original.name}${this.original.extension}`
    }
  },
  async mounted () {
    this.model = this.document

    await this.getDocument()

    await this.getReportTypes()
    await this.getGroups()
    await this.getEmailTemplates()

    this.original = { ...this.model } // remove reference to original & duplicate data for change detection

    if (!this.model.id) {
      this.getDocument()
    }
    window.addEventListener('beforeunload', this.shouldLeave)
  },
  methods: {
    async finally (payload, hadError) {
      if (!hadError) {
        let response = await this.axios.put('documents/' + this.model.id, payload, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })

        await this.complete(response)
      } else {
        this.isSaving = false
      }
    },
    async complete (response) {
      if (response.status >= 200 && response.status < 300) {
        let that = this

        setTimeout(async () => {
          that.isSaving = false
          await that.getDocument()
          that.$router.push({
            name: 'DocumentsCenterScreen'
          })
        }, 500)
      } else {
        this.isSaving = false
      }
    },
    hasChanged () {
      let hasChanged = false
      Object.keys(this.model).forEach(_key => {
        if (this.model[_key] !== this.inputHash[_key] && this.model[_key] !== null && this.model[_key] !== undefined) {
          hasChanged = true
        }
      })

      return hasChanged
    },
    confirmLeave () {
      return confirm('Do you really want to leave? You have unsaved changes!')
    },
    shouldLeave (e) {
      let shouldLeave = true

      try {
        if (this.input.files !== undefined && (this.input.files.length > 0 || this.hasChanged())) {
          if (!this.confirmLeave()) {
            if (e !== null && e !== undefined) {
              // Cancel the event
              e.preventDefault()
              // Chrome requires returnValue to be set
              e.returnValue = ''
            }

            shouldLeave = false
          }
        }
      } catch (_error) {
      }

      return shouldLeave
    },
    beforeWindowUnload (e, next) {
      let shouldLeave = this.input.files !== undefined && ((this.input.files.length > 0 || this.hasChanged()))
      if (shouldLeave && !this.shouldLeave()) {
        next(false)
      } else {
        next()
      }
    },
    async handleFormSubmit () {
      let that = this
      let payload = new FormData()
      let files = this.$refs.filePond.getFiles()
      this.isSaving = true

      setCompOptions(this, this.model.id)

      if (files.length > 0) {
        this.$refs.filePond.processFiles().then(() => {
          // Put code to finish up and back out
        })
      } else {
        let hadError = false

        Object.keys(this.model).forEach(function (key) {
          if (key === 'yearOverride' && that.model[key].toString().length !== 4) {
            that.inputError.yearOverride = 'Year Override must have 4 digits.'
            hadError = true
          }

          if (key === 'uploadDate' && that.model[key] instanceof Date) {
            payload.append(key.charAt(0).toUpperCase() + key.slice(1), that.model[key].toDateString())
          } else {
            payload.append(key.charAt(0).toUpperCase() + key.slice(1), that.model[key]) // append the modified data
          }
        })

        if (!hadError) {
          this.inputError.yearOverride = false
        }

        this.finally(payload, hadError)
      }
    },
    async getGroups () {
      let response = await this.axios.get('carriers?page=1&perPage=1000&sortBy=code')

      if (response.status === 200) {
        this.groups = response.data.records
      }

      return []
    },
    handleSelectChange (select) {
      if (select.identifier === 'emailTemplate') {
        this.$set(this.input, 'emailTemplate', select.value)
      }

      if (select.identifier === 'documentType') {
        this.$set(this.input, 'documentType', select.value)
      }

      if (select.identifier === 'reportType') {
        this.$set(this.input, 'reportType', select.value)
      }
    },
    handleIsChecked (event) {
      this.shouldSendNotification = event.isChecked
    },
    removefile (arg, file) {
      if (this.input.files) {
        this.input.files = this.input.files.filter(function (current) {
          if (current.name !== file.file.name) {
            return file
          }
        })
      }
    },
    addfile (arg, file) {
      if (file && (file.filenameWithoutExtension.replaceAll('—', '-').replaceAll('–', '-').split('-')).length > 1) {
        let that = this
        let meta = file.filenameWithoutExtension.replaceAll('—', '-').replaceAll('–', '-').split('-')
        this.errors = []

        // Do not add the same file twice.
        this.$refs.filePond.getFiles().forEach(function (current) {
          if (current.id !== file.id && current.file.name === file.file.name) {
            that.$refs.filePond.removeFile(current, {})
          }
        })

        /*
          {XXX}-{CCC}-{YY}-{NAME}.{EXT}
          XXX = Carrier ("BCC" or "NML", "MM", etc)
          CCC = Report Type Code
          YY = Year
          NAME = Document Name
          EXT = File Extension
        */

        let isValid = new RegExp(/([a-zA-Z]{2,4})-([a-zA-Z]{3})-([0-9]{2})-([a-zA-Z0-9 ()-,.]+)(.[.a-zA-Z]{3,4})/g).test(file.filename.replaceAll('—', ''))

        if (isValid && meta.length > 1) {
          const isValidGroup = meta[0] !== undefined && this.groups.filter(function (group) {
            if (meta[0] === group.code) {
              return group
            }
          }).length === 1

          let isValidReportType = meta[1] !== undefined && this.reportTypesArray.filter(function (type) {
            if (meta[1] === type.code) {
              return type
            }
          }).length === 1

          const isValidYear = meta[2] !== undefined && !isNaN(parseInt(meta[2]))
          const hasName = meta[3] !== undefined && meta[3] !== ''
          const hasExtension = file.fileExtension && file.fileExtension !== ''

          this.handleError(file.filename + ': The specified group does not exist.', isValidGroup)
          this.handleError(file.filename + ': The specified report type does not exist.', isValidReportType)
          this.handleError(file.filename + ': The specified year is not valid.', isValidYear)
          this.handleError(file.filename + ': No name was specified.', hasName)
          this.handleError(file.filename + ': No file extension provided', hasExtension)

          if (this.reportTypesMap.get(meta[1]) && this.reportTypesMap.get(meta[1]).category === 'client') {
            this.errors.push(file.filename + `: references an invalid report type code "${meta[1]}" for client reports. This type of document doesn't belong here.`)
            isValidReportType = false // Override the previously set value.
            file.abortLoad()
            that.$refs.filePond.removeFile(file, {})
            return
          }

          if (
            isValidGroup &&
            isValidReportType &&
            isValidYear &&
            hasName &&
            hasExtension
          ) {
            this.model.displayName = meta[3]
            this.model.name = meta[3]
            this.model.typeCode = meta[1]
            this.model.carrier = meta[0]
            this.model.extension = file.fileExtension
            this.model.yearOverride = this.parseYearOverride(parseInt(meta[2]))
            this.model.reportType = this.model.TypeCode

            this.$refs.documentType.setSelected(this.model.typeCode)

            this.$forceUpdate()

            this.input.files.push(file.file)
          } else {
            file.abortLoad()
            this.errors.push(`${file.filename}: The specified name does not meet naming requirements.`)
          }
        } else {
          that.$refs.filePond.removeFile(file, {})
        }
      } else if (file) {
        this.errors.push(`${file.filename}: The specified name does not meet naming requirements.`)
        file.abortLoad()
      }
    },
    handleError (error, isValid) {
      if (this.fileError.indexOf(error) === -1 && !isValid) {
        this.fileError.push(error)
      } else if (this.fileError.indexOf(error) !== -1 && isValid) {
        this.fileError = this.fileError.filter(function (errorString) {
          if (errorString !== error) {
            return errorString
          }
        })
      }
    },
    async getDocument () {
      const response = await this.axios.get('documents/' + this.$route.params.id)

      if (response.status === 200) {
        this.model = response.data
        this.model.uploadDate = this.getUploadDate(this.model.uploadDate).format()

        this.inputHash = this.clone(this.model, true)
        setCompOptions(this, parseInt(this.model.id))
      }

      this.original = this.model
    },
    async getReportTypes () {
      let collection = {}
      let response = await this.axios.get('report-types', {
        perPage: 1000,
        page: 1
      })

      if (response.status === 200) {
        let types = response.data.records
        this.reportTypesArray = types

        types.filter(type => type.category !== 'client' && type.category !== '' && type.category).forEach(function (type) {
          collection[type.code] = type.name
        })

        this.reportTypes = collection
        this.reportTypesMap = new Map(this.reportTypesArray.map(type => [type.code, type]))
      }
    },
    getFileType (extension = '') {
      switch (extension) {
        case '.pdf':
          return 'Adobe Acrobat PDF'
        case '.xls':
          return 'Microsoft Excel Spreadsheet'
        case '.xlsx':
          return 'Microsoft Excel Spreadsheet'
        default:
          return extension ? extension.toUpperCase() + ' File' : 'N/A'
      }
    },
    async getEmailTemplates () {
      let collection = {}
      let response = await this.axios.get('email-templates', {
        perPage: 1000,
        page: 1
      })

      if (response.status === 200) {
        let types = response.data.records

        types.forEach(function (type) {
          collection[type.id] = type.name
        })

        this.$set(this, 'emailTemplates', collection)
      }
    },
    viewDocument (document) {
      this.$router.push({ name: 'ViewDocumentScreen', params: { id: document.id, document: document } })
    }
  }
}
</script>

<style scoped>
#body-content-area {
  position: absolute;
  top: 35px;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

#pagelayout {
  right: 100%;
  bottom: 0;
}

.filepond--root {
  height: 80px !important;
}

  .saving {
    background: #d9d9d9!important;
  }
</style>
